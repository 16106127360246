// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/OpenSans-Light.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/OpenSans-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts/OpenSans-SemiBold.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./fonts/OpenSans-Bold.woff2");
exports = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "@font-face{font-family:'Open Sans';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");font-weight:300;font-style:normal;font-display:swap}@font-face{font-family:'Open Sans';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");font-weight:normal;font-style:normal;font-display:swap}@font-face{font-family:'Open Sans';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\");font-weight:600;font-style:normal;font-display:swap}@font-face{font-family:'Open Sans';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\");font-weight:bold;font-style:normal;font-display:swap}\n", "",{"version":3,"sources":["fonts.scss"],"names":[],"mappings":"AAAA,WAAW,uBAAuB,CAAC,2DAAuD,CAAC,eAAe,CAAC,iBAAiB,CAAC,iBAAiB,CAAC,WAAW,uBAAuB,CAAC,2DAAyD,CAAC,kBAAkB,CAAC,iBAAiB,CAAC,iBAAiB,CAAC,WAAW,uBAAuB,CAAC,2DAA0D,CAAC,eAAe,CAAC,iBAAiB,CAAC,iBAAiB,CAAC,WAAW,uBAAuB,CAAC,2DAAsD,CAAC,gBAAgB,CAAC,iBAAiB,CAAC,iBAAiB","file":"fonts.scss","sourcesContent":["@font-face{font-family:'Open Sans';src:url(\"./fonts/OpenSans-Light.woff2\") format(\"woff2\");font-weight:300;font-style:normal;font-display:swap}@font-face{font-family:'Open Sans';src:url(\"./fonts/OpenSans-Regular.woff2\") format(\"woff2\");font-weight:normal;font-style:normal;font-display:swap}@font-face{font-family:'Open Sans';src:url(\"./fonts/OpenSans-SemiBold.woff2\") format(\"woff2\");font-weight:600;font-style:normal;font-display:swap}@font-face{font-family:'Open Sans';src:url(\"./fonts/OpenSans-Bold.woff2\") format(\"woff2\");font-weight:bold;font-style:normal;font-display:swap}\n"]}]);
// Exports
module.exports = exports;
